import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Countdown, { zeroPad } from 'react-countdown';
import './HeaderFeature.scss';

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed
}) => {
  if (completed) {
    return <span>Countdown ended!</span>;
  } else {
    return (
      <span className="digits">
        {' '}
        {days}
        {'D: '}
        {zeroPad(hours)}
        {'H: '}
        {zeroPad(minutes)}
        {'M: '}
        {zeroPad(seconds)}
        {'S'}
      </span>
    );
  }
};

export const HeaderFeature = (props) => {
  const {
    imageSrc,
    productTitle,
    endDateTime,
    button = {
      text: 'Get yours',
      link: '#'
    }
  } = props;

  const [hasEnded, setHasEnded] = useState(false);

  return (
    <>
      { !hasEnded && (
        <a href={ button.link } className="header__feature">
          { imageSrc && (
            <img
              className="header__feature-image"
              src={ imageSrc }
              alt={ productTitle }
              width="40"
              height="40"
            />
          ) }
          <div className="header__feature-content">
            <div className="header__feature-details">
              <h2 className="header__feature-title">{ productTitle }</h2>
              <p className="header__feature-end-date">
                <Countdown
                  date={ endDateTime }
                  renderer={ renderer }
                  onComplete={ () => setHasEnded(true) }
                />
              </p>
            </div>
            <a
              className="header__feature-button"
              href={ button.link }
            >
              <span className="button-label">{ button.text }</span>
              <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.7205 8.7364C22.1111 8.34588 22.1111 7.71271 21.7205 7.32219L15.3566 0.958229C14.966 0.567705 14.3329 0.567705 13.9424 0.958229C13.5518 1.34875 13.5518 1.98192 13.9424 2.37244L19.5992 8.0293L13.9424 13.6862C13.5518 14.0767 13.5518 14.7098 13.9424 15.1004C14.3329 15.4909 14.966 15.4909 15.3566 15.1004L21.7205 8.7364ZM0 9.0293H21.0134V7.0293H0V9.0293Z" fill="white" />
              </svg>
            </a>
          </div>
        </a>
      ) }
    </>
  );
};

HeaderFeature.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  endDateTime: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired
};

export default HeaderFeature;
