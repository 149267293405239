
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import Loader from 'components/Loader';
import MainLayout from 'containers/MainLayout';
import Pagination from 'components/Pagination';
import ProductTile from 'components/ProductTile';
import SEO from 'components/SEO';
import ReactGA from 'react-ga';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import './Collection.scss';
import { manageZendeskVisibility } from 'utils/zendeskUtils';

export const Collection = (props) => {
  const {
    storeId,
    storeCollections,
    fetchCollection,
    location,
    storeData,
    localizationData,
    currency,
    storeName,
    sellerId
  } = props;
  const [loading, setLoading] = useState(false);
  const collectionId = location.pathname.split('/')[1];
  const storeCollection = storeCollections[collectionId];
  const products = get(storeCollection, 'products', []);
  const tiles = map(products, (product, i) => (
    <ProductTile product={ product } key={ product.id } list={ collectionId } position={ i } />
  ));
  useEffect(() => {
    ReactGA.event({
      category: 'engagement',
      action: 'view_category',
      label: JSON.stringify({ collectionId })
    }, ['default', 'client']);
  }, [collectionId]);

  useEffect(() => {
    const getCollection = async () => {
      if ((!storeCollection || get(storeCollection, 'total_count') > 0) && isEmpty(products)) {
        setLoading(true);
        await fetchCollection(storeId, collectionId, false, localizationData);
        setLoading(false);
      }
    };

    getCollection();
  }, [storeCollection, fetchCollection, storeId, collectionId, localizationData, products]);

  const collectionName = get(storeCollection, 'collection');
  const seoDescription = `Shop ${collectionName} on ${storeName}! Browse all products from the collection with quality and fast delivery.`;
  useEffect(() => {
    manageZendeskVisibility(true);
  }, []);
  return (
    <>
      <SEO
        title={ `${collectionName} | ${storeName}` }
        description={ seoDescription }
      />
      <MainLayout location={ location } storeData={ storeData }>
        <div className="collection-content content">
          { loading && isEmpty(products)
            ? <Loader />
            : (
              <Fragment>
                <span className="wookiee" style={ { paddingLeft: '0.6em' } }>{ storeCollection.collection }</span>
                <div className="tile-heading">{ storeCollection && <h2>{ storeCollection.collection }</h2> }</div>
                <Pagination
                  items={ tiles }
                  storeName={ storeName }
                  sellerId={ sellerId }
                  currency={ currency }
                  list={ collectionId }
                  anchor=".collection-content"
                  products={ products }
                />
              </Fragment>
            )
          }

          { (!loading && get(storeCollection, 'total_count') === 0) && <span>There are no products for this category</span> }
        </div>
      </MainLayout>
    </>
  );
};

Collection.defaultProps = {
  storeId: null,
  storeCollections: {}
};

const {
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string,
  array
} = propTypes;

Collection.propTypes = {
  storeId: string,
  fetchCollection: func.isRequired,
  storeCollections: shape({
    accessories: object,
    products: arrayOf(shape({
      imageUrl: string,
      daysLeft: number,
      id: number,
      name: string,
      price: string,
      productName: string,
      timeLeft: string,
      url: string
    })),
    count: number,
    total_count: number,
    page: number,
    next: string,
    per_page: number,
    facebook_pixel_html: string,
    collection: string
  }),
  location: shape({
    hash: string,
    pathname: string,
    search: string,
    state: object
  }).isRequired,
  storeData: shape({
    banner_url: string,
    collections: array,
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: object,
    theme_color: string,
    url: string,
    use_logo: bool,
    location: object
  }).isRequired,
  localizationData: shape({}).isRequired,
  currency: string.isRequired,
  storeName: string.isRequired,
  sellerId: number.isRequired
};

const mapStateToProps = state => ({
  currency: state.localizationData.buyer_currency,
  storeName: state.stores.name,
  sellerId: state.stores.sellerId
});

export default connect(mapStateToProps)(Collection);
