import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveModal } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { ModalConductor } from '@springforcreators/propel-ui';
import CartConfirmation from 'pages/ListingPage/CartConfirmation';
import UnavailableItemsModal from 'components/UnavailableItemsModal';
import QuickAddToCartModal from 'components/ProductTile/ProductOptions/QuickAddToCartModal';
import { bpProps } from 'utils/responsiveUtils';
import stripePromise from 'utils/stripeUtils';
import { getCartSize } from 'utils/cartUtils';
import './HeaderCart.scss';
import QuickAddOutOfStockModal from '../../ProductTile/ProductOptions/QuickAddOutOfStockModal';

export const HeaderCart = () => {
  const { activeModal } = useSelector(state => state);
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const dispatch = useDispatch();
  const { userCart } = useSelector(state => state);
  const { userCartItem } = useSelector(state => state?.activeModal?.mProps);
  const cartSize = getCartSize(userCart) || 0;
  const [preventBackgroundScroll, setPreventBackgroundScroll] = useState(true);

  useEffect(() => {
    setPreventBackgroundScroll(activeModal.id === 'cart-confirmation');
  }, [activeModal.id]);

  let cartCount = cartSize;
  if (cartSize > 99 || cartSize <= 0) {
    cartCount = cartSize > 99 ? '99+' : '';
  }

  return (
    <div className="header__cart">
      <Link to="/cart">
        <div className="header__cart_button pr_btn pr_btn--md label-s">
          <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9_163)">
              <path d="M3.2781 2.454H0V0.354004H5.1219L5.67 4.554H19.95V11.715L4.87935 14.7285L3.2781 2.454ZM18.9 21.354C18.343 21.354 17.8089 21.1327 17.4151 20.7389C17.0212 20.3451 16.8 19.811 16.8 19.254C16.8 18.697 17.0212 18.1629 17.4151 17.7691C17.8089 17.3752 18.343 17.154 18.9 17.154C19.457 17.154 19.9911 17.3752 20.3849 17.7691C20.7787 18.1629 21 18.697 21 19.254C21 19.811 20.7787 20.3451 20.3849 20.7389C19.9911 21.1327 19.457 21.354 18.9 21.354ZM6.3 21.354C5.74304 21.354 5.2089 21.1327 4.81508 20.7389C4.42125 20.3451 4.2 19.811 4.2 19.254C4.2 18.697 4.42125 18.1629 4.81508 17.7691C5.2089 17.3752 5.74304 17.154 6.3 17.154C6.85695 17.154 7.3911 17.3752 7.78492 17.7691C8.17875 18.1629 8.4 18.697 8.4 19.254C8.4 19.811 8.17875 20.3451 7.78492 20.7389C7.3911 21.1327 6.85695 21.354 6.3 21.354Z" fill="#0E60FF" />
            </g>
            <defs>
              <clipPath id="clip0_9_163">
                <rect width="21" height="21" fill="white" transform="translate(0 0.354004)" />
              </clipPath>
            </defs>
          </svg>
          {cartSize >= 1 && <span className="header__cart__count">{cartCount}</span>}
        </div>
      </Link>

      <Elements stripe={ stripePromise }>
        <ModalConductor
          activeModal={ activeModal }
          preventBackgroundScroll={ preventBackgroundScroll }
          setActiveModal={ (state, props) => {
            const updatedProps = activeModal.id === 'cart-confirmation' ? { ...props, userCartItem } : props;
            dispatch(setActiveModal(state, updatedProps));
          } }
          transitionOrigin={ activeModal.id === 'cart-confirmation' && bpIsLT('mobileLg') ? 'bottom' : 'top' }
          modals={ [
            {
              id: 'cart-confirmation',
              className: 'cartconfirm',
              header: 'Added to cart',
              hideCloseBtn: true,
              node: <CartConfirmation />
            },
            {
              id: 'quick-add-confirmation',
              className: 'quick__add__modal',
              node: <QuickAddToCartModal />
            },
            {
              id: 'quick-add-out-of-stock',
              className: 'quick__add__modal__oos',
              node: <QuickAddOutOfStockModal />
            }
          ] }
        />
        <ModalConductor
          activeModal={ activeModal }
          setActiveModal={ (state, props) => {
            dispatch(setActiveModal(state, props));
          } }
          modals={ [
            {
              id: 'cart-unavailable-items',
              className: 'unavailable-items',
              node: <UnavailableItemsModal />
            }
          ] }
        />
      </Elements>
    </div>
  );
};

export default HeaderCart;
