/*eslint-disable no-shadow */
import React, { Fragment, useEffect, useState } from 'react';
import Pagination from 'components/Pagination';
import get from 'lodash/get';
import { Button, Skeleton } from '@springforcreators/propel-ui';
import ProductTile from 'components/ProductTile';
import ThemeWrapper from 'containers/ThemeWrapper';
import { Waypoint } from 'react-waypoint';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import tracker from 'utils/tracking';
import { PRODUCT_IMPRESSIONS, pushEvent } from '../../utils/tracking/gtm';
import removeCurrencySymbol from '../../lib';
import { USE_COLLECTIONS } from '../../constants';
import { ProductTileCollections } from '../ProductTileCollections';
import './ProductFeed.scss';
import ProductFeedSkeleton from './ProductFeedSkeleton';
//import CustomCollection from '../CustomCollection';
import DropHeading from './DropHeading';

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed
}) => {
  if (completed) {
    return <span>Countdown ended!</span>;
  } else {
    return (
      <span className="digits">
        {' '}
        {days}
        {'D: '}
        {zeroPad(hours)}
        {'H: '}
        {zeroPad(minutes)}
        {'M: '}
        {zeroPad(seconds)}
        {'S'}
      </span>
    );
  }
};

const ProductFeed = (props) => {
  const {
    storeProducts,
    isFetching,
    storeData,
    fetchProducts,
    themeData
    // getStyles
  } = useGlobalProps();

  const {
    // title,
    localizationData,
    storeName,
    previewMode,
    storeListings
  } = props;

  const { layout } = themeData;

  const {
    products,
    page,
    totalPages,
    next
  } = storeProducts;
  const perPage = storeProducts.per_page;
  const showMoreProducts = USE_COLLECTIONS ? page !== totalPages : !!next;
  const { productCacheEnabled } = storeListings;
  const currentPage = page;

  const [hasEnded, setHasEnded] = useState(false);

  let tiles = [];

  if (products && products.length) {
    tiles = products.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  const handleLoadMore = () => {
    tracker.track(`homepage.load_more.clicked`, { productCount: products.length });
    fetchProducts(currentPage + 1);
  };

  useEffect(() => {
    if (products.length) {
      const latestFetchedProducts = products.slice(products.length - perPage || 0, products.length);
      const productImpressions = latestFetchedProducts.map((product, position) => (
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          list: 'Featured Products',
          position: position + 1,
          dimension8: get(storeData, 'sellerId'),
          dimension9: get(product, 'listingId')
        }
      ));
      const eventBody = {
        currencyCode: get(localizationData, 'buyer_currency'),
        impressions: productImpressions
      };
      pushEvent(PRODUCT_IMPRESSIONS, eventBody);
    }
  }, [products]);

  return (
    <Fragment>
      <div className="anchor" id="featured-products" />
      <div className="feature-row" style={ { backgroundImage: 'url(/assets/maul-feature.png)', backgroundSize: 'cover', display: 'none' } }>
        <div className="grid-container" style={ { paddingTop: '32px' } }>
          <div className="column" style={ { paddingLeft: '16px', paddingRight: '16px' } }>
            <a href="/listing/revenge-half-zip-fleece">
              <img style={ { maxWidth: '80%' } } alt="ORDER 66 Maul Fleece" src="/assets/order-66-text.png" />
              {hasEnded ? (
                <span>Ended</span>
              ) : (
                <>
                  <div style={ {
                    display: 'flex',
                    marginTop: '16px',
                    flexDirection: 'row',
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    gap: '8px'
                  } }
                  >
                    <span>Ends in</span>
                    <span>
                      <Countdown
                        date="2024-05-28T06:59:00Z"
                        renderer={ renderer }
                        onComplete={ () => setHasEnded(true) }
                      />
                    </span>
                  </div>
                </>
              )}
            </a>
          </div>
          <div className="column">
            <a href="/listing/revenge-half-zip-fleece">
              <img style={ { marginBottom: '-19px' } } alt="" src="/assets/maul-fleece-large.png" />
            </a>
          </div>
        </div>
      </div>
      <div className="product-feed">
        <div className="tile-heading">
          {isFetching && !products?.length ? (
            <Skeleton styles={ { height: 30, width: '45%' } } />
          ) : (
            <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
              <span className="wookiee">Featured products</span>
              <DropHeading title="Featured products" />
            </div>
          )}
        </div>

        { isFetching && !products?.length && (
          <ProductFeedSkeleton />
        ) }

        { (previewMode || get(layout, 'productList.pagination'))
          ? <Pagination items={ tiles } />
          : <div className="product-tile-grid" data-cy="product-tile-grid">{ tiles }</div>
        }

        { showMoreProducts && !productCacheEnabled && (
          <>
            { currentPage < 2 ? (
              <Waypoint
                onEnter={
                  (wp) => {
                    if (!isFetching && wp.previousPosition === 'below' && !get(layout, 'productList.pagination')) {
                      const { page } = storeProducts;
                      fetchProducts(page + 1);
                    }
                  }
                }
              />
            ) : (
              <div className="center-content mb4">
                { !isFetching && (
                  <Button
                    onClick={ handleLoadMore }
                  >
                    Load More
                  </Button>
                )}
              </div>
            )}

          </>
        ) }
      </div>
      <div className="feature-row" style={ { backgroundColor: 'black' } }>
        <video autoPlay={ true } loop={ true } muted={ true } playsInline={ true } style={ { width: '100%', height: 'auto' } }>
          <source src="/assets/youtube-feature.mp4" type="video/mp4" />
        </video>
        <div className="grid-container" style={ { paddingTop: '32px' } }>
          <div className="column" style={ { paddingLeft: '16px', paddingRight: '16px' } }>
            <a href="https://www.youtube.com/@StarWarsTheory" target="_blank" rel="noreferrer">
              <img style={ { maxWidth: '80%' } } alt="Watch the newest theory" src="/assets/watch-the-latest.png" />
            </a>
          </div>
          <div className="column">
            <div className="spacer" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const { string, shape, bool } = propTypes;

ProductFeed.propTypes = {
  title: string,
  localizationData: shape({}).isRequired,
  storeName: string,
  previewMode: bool.isRequired,
  storeListings: shape({}).isRequired
};

ProductFeed.defaultProps = {
  title: null,
  storeName: ''
};

const mapStateToProps = state => ({
  localizationData: state.localizationData,
  storeName: state.stores.name,
  previewMode: get(state, 'themeData.previewMode'),
  storeListings: get(state, 'storeListings')
});

export default connect(mapStateToProps)(ThemeWrapper(ProductFeed, ['content.productList', 'styles.productList', 'layout.productList']));
