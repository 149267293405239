import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Countdown, { zeroPad } from 'react-countdown';
import './CountDownTimer.scss';

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed
}) => {
  if (completed) {
    return <span>Ended!</span>;
  } else {
    return (
      <span className="digits">
        {' '}
        <div className="digit">
          {days}
          <span className="label">Days</span>
        </div>
        {' : '}
        <div className="digit">
          {zeroPad(hours)}
          <span className="label">Hours</span>
        </div>
        {' : '}
        <div className="digit">
          {zeroPad(minutes)}
          <span className="label">Mins</span>
        </div>
        {' : '}
        <div className="digit">
          {zeroPad(seconds)}
          <span className="label">Secs</span>
        </div>
      </span>
    );
  }
};

export const CountDownTimer = (props) => {
  const {
    endDateTime
  } = props;

  const [hasEnded, setHasEnded] = useState(false);

  return (
    <>
      { !hasEnded && (
        <div className="timer__feature-content">
          <div className="timer__feature-details">
            <h4>Ending in</h4>
            <p className="timer__feature-end-date">
              <Countdown
                date={ endDateTime }
                renderer={ renderer }
                onComplete={ () => setHasEnded(true) }
              />
            </p>
          </div>
        </div>
      ) }
    </>
  );
};

CountDownTimer.propTypes = {
  endDateTime: PropTypes.string.isRequired
};

export default CountDownTimer;
