import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';
import MainMenu from 'components/MainMenu';
import FlyOutMenu from 'components/FlyOutMenu';
import SocialMediaIcons from 'components/SocialMediaIcons';
import propTypes, { func } from 'prop-types';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import tracker from 'utils/tracking';
import HubspotForm from 'react-hubspot-form';
import { bpProps } from 'utils/responsiveUtils';
import { setActiveModal } from 'redux/actions';
import { ModalConductor } from '@springforcreators/propel-ui';
import headerTemplates from './headerTemplates';
import HeaderCart from './HeaderCart';
import HeaderSearch from './HeaderSearch';
import './Header.scss';
import HeaderFeature from './HeaderFeature';

const Header = (props) => {
  const {
    brand,
    content,
    styles,
    meta,
    stores,
    getStyles,
    bpIsGT,
    bpIsLT
  } = props;

  const dispatch = useDispatch();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const hamburgerClasses = hamburgerOpen ? 'is-active' : 'is-inactive';
  const { activeModal } = useSelector(state => state);
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  // Function to toggle categories menu
  const toggleCategoriesOpen = () => {
    setCategoriesOpen(!categoriesOpen);
  };

  if (!stores) return false;

  const { collections } = stores;
  const sortedCollections = [...collections].sort((a, b) => a.name.localeCompare(b.name));
  const trackLogoClicked = () => tracker.track('header.logo.clicked');
  const trackAboutClicked = () => tracker.track('header.about.clicked');
  const trackSearchClicked = () => tracker.track('header.search.clicked');

  const customLinks = get(content, 'header.customLinks') || [];

  if (get(content, 'header.showAbout') && !customLinks.find(link => link.name === 'About')) {
    customLinks.push({
      name: 'About',
      slug: 'about',
      action: trackAboutClicked
    });
  }

  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) return srcValue;
    return undefined;
  };

  const templateStyles = headerTemplates[get(styles, 'header.template')];
  const baseHeaderStyles = get(styles, 'header');

  // Merge header template styles & custom styles into single object
  const headerStyles = mergeWith(templateStyles, baseHeaderStyles, customizer);
  const logoSrc = '/assets/theorymerch.svg';

  const logoContent = logoSrc && logoSrc !== 'null' // When there is no image passed in to the store preview
    ? (
      <img
        src={ logoSrc }
        alt={ get(meta, 'storeName') }
      />
    )
    : (
      <h3 style={ getStyles('textStyles', headerStyles) }>
        { get(meta, 'storeName') }
      </h3>
    );

  const showSocialMedia = get(content, 'header.showSocialIcons') && get(brand, 'socialMedia') && get(brand, 'socialMedia').length > 0;

  const exploreMenuItem = { name: 'Explore', slug: '' };
  const customMenuItems = [];
  const menuItems = [exploreMenuItem, ...sortedCollections.concat(customLinks), ...customMenuItems];
  const menuLeft = [exploreMenuItem];
  const categoryMenu = [...sortedCollections.concat(customLinks)];

  return (
    <header
      className={ `header universal ${get(headerStyles, 'className') || ''}` }
      style={ {
        /*
        Removing Store builder defined styles
        ...getStyles('bgStyles', headerStyles),
        ...getStyles('textStyles', headerStyles)
        */
      } }
    >
      <div className={ `${getStyles('constrain', headerStyles) ? 'row' : 'fullrow'}` }>
        <div
          className="header__inner"
          style={ getStyles('container', headerStyles) }
        >
          { (collections.concat(customLinks).length > 0 || showSocialMedia) && (
            <div className="header__hamburger" style={ getStyles('hamburger', headerStyles) }>
              <button
                type="button"
                className={ `hamburger-button hamburger-button--minus ${hamburgerClasses}` }
                onClick={ () => setHamburgerOpen(!hamburgerOpen) }
              >
                <span className="hamburger-button-box">
                  <span
                    className="hamburger-button-inner"
                    style={ { backgroundColor: getStyles('textStyles.color', headerStyles) } }
                  />
                </span>
              </button>
              { hamburgerOpen && (
                <FlyOutMenu
                  menuItems={ menuItems }
                  isOpen={ true }
                  classes="align-left"
                  onFocusBlur={ () => { setHamburgerOpen(false); } }
                  showSocialIcons={ showSocialMedia && bpIsLT('tabletMd') }
                  showSubcategories={ true }
                />
              ) }
            </div>
          ) }
          <div className="header__left">
            { (collections.concat(customLinks).length > 0 && get(content, 'header.showCategories')) && (
              <div className="header__menuitems" style={ getStyles('menuItems', headerStyles) }>
                <MainMenu menuItems={ menuLeft } />
              </div>
            ) }
            <div
              className="header__menuitems"
              style={ getStyles('menuItems', headerStyles) }
              onClick={ toggleCategoriesOpen
              }
              onKeyDown={ (event) => { if (event.key === 'Enter' || event.key === ' ') toggleCategoriesOpen(); } }
              role="button"
              tabIndex="0"
            >
              <div className="header-menu">
                <li className="menu-item">
                  <span>Categories</span>
                </li>
              </div>
            </div>
            <FlyOutMenu
              menuItems={ categoryMenu }
              isOpen={ categoriesOpen }
              classes="align-left category-menu"
              showSocialIcons={ showSocialMedia && bpIsLT('tabletMd') }
              showSubcategories={ true }
            />
          </div>
          <div className="header__center">
            <div className="header__logo">
              { get(content, 'header.logoHref')
                ? <a href={ get(content, 'header.logoHref') } onClick={ trackLogoClicked }>{ logoContent }</a>
                : <Link to="/" onClick={ trackLogoClicked }>{ logoContent }</Link>
              }
            </div>
          </div>
          <div className="header__right" style={ getStyles('actions', headerStyles) }>
            <HeaderFeature
              imageSrc="/assets/maul-fleece.png"
              productTitle="Revenge Fleece"
              endDateTime="2024-05-28T06:59:00Z"
              button={ {
                text: 'Get yours',
                link: '/listing/revenge-half-zip-fleece'
              } }
            />
            <div>
              { !get(content, 'header.hideSearch') && (
                <Link to="/search" onClick={ trackSearchClicked } className="search-toggle">
                  <HeaderSearch />
                </Link>
              ) }
              { (
                showSocialMedia && bpIsGT('tabletMd')
              ) && (
                <SocialMediaIcons
                  styles={ getStyles('socialStyles', headerStyles) }
                  limit={ bpIsLT('desktopSm') ? 3 : null }
                />
              ) }
              <ModalConductor
                activeModal={ activeModal }
                setActiveModal={ state => dispatch(setActiveModal(state)) }
                modals={ [{
                  id: 'getnotified-modal',
                  header: 'Get notified',
                  className: 'getnotified',
                  node: <HubspotForm
                    portalId="23573381"
                    formId="43caf95d-f38c-4131-a438-819121a6f8b8"
                    loading={ <div>Loading...</div> }
                  />
                }] }
              />
              <button
                style={ {
                  display: 'none'
                } }
                type="button"
                className="getnotified"
                onClick={ () => dispatch(setActiveModal('getnotified-modal')) }
              >
                <img src="/assets/bell-icon.svg" alt="notify" />
                <span>Get notified</span>
              </button>
              <HeaderCart />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const {
  shape,
  string,
  array,
  bool
} = propTypes;

Header.propTypes = {
  brand: shape({}).isRequired,
  styles: shape({
    header: shape({})
  }).isRequired,
  content: shape({
    header: shape({
      logo: string,
      showSocialIcons: bool
    })
  }).isRequired,
  meta: shape({
    storeName: string
  }).isRequired,
  stores: shape({
    collections: array
  }).isRequired,
  getStyles: func.isRequired,
  bpIsGT: func.isRequired,
  bpIsLT: func.isRequired
};

const mapStateToProps = state => ({
  stores: state.stores,
  brand: get(state, 'themeData.brand'),
  content: get(state, 'themeData.content'),
  styles: get(state, 'themeData.styles'),
  meta: get(state, 'themeData.meta'),
  ...bpProps(state)
});

export default connect(mapStateToProps)(Header);
