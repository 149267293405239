/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import { Button, SelectField } from '@springforcreators/propel-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import has from 'lodash/has';
import PreorderFormButton from 'components/PreorderFormButton';
import SizeChartButton from 'components/SizeChartButton';
import QtyField from 'components/QtyField';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import AfterPay from 'components/AfterPay';
import stripePromise from 'utils/stripeUtils';
import { Elements } from '@stripe/react-stripe-js';
import { ADD_TO_CART, pushEvent } from 'utils/tracking/gtm';
import { useCheckout, usePrevious } from 'hooks';
import { fetchListingInventoryCount, setActiveModal } from 'redux/actions';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { generateBaseSku } from 'utils/productUtils';
import { bpProps } from 'utils/responsiveUtils';
import VariantSelector from 'components/VariantSelector';
import SingleSelector from 'components/SingleSelector';
import { useLocation } from 'react-router';
import {
  bool, func, number, shape, string
} from 'prop-types';
import tracker from 'utils/tracking';
import CountDownTimer from 'components/CountDownTimer';
import { HIDE_AFTERPAY } from '../../../constants';

/**
 * Renders listingForm component
 * @param {boolean} showSingleOptions Determines whether or not to show the
 *                                    option selectors when there is only one selection.
 *                                    Defaults to true.
 * @return {node} Returns a react node
 */
const ListingForm = ({
  showSingleOptions = true,
  listing,
  listingProduct,
  variant,
  activeVariationIndex = 0,
  activeSizeIndex = 0,
  primaryProduct,
  itemGroupId,
  productId,
  showPreorderForm,
  onProductVariationChange,
  price,
  onFormSubmit,
  onProductSizeChange,
  cartData,
  eventProduct,
  trackPartnersCookieInParams
}) => {
  const { getStyles } = useGlobalProps();
  const {
    storeListings,
    inventory,
    themeData,
    localizationData,
    stores
  } = useSelector(state => state);
  const { content } = themeData;
  const { bpIsLT, bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  const [quantity, setQuantity] = useState(1);
  const [stickyMenu, setStickyMenu] = useState(false);
  const [ctaScrolledTo, setCtaScrolledTo] = useState(false);
  const { usCheckoutOnly } = useCheckout();
  const location = useLocation();

  const currency = localizationData?.buyer_currency;
  const colorId = get(variant, 'variationId');
  const sizeId = get(variant, `sizes[${activeSizeIndex}].id`);
  const key = `${listing?.listingId}-${colorId}-${sizeId}`;
  const initialSku = generateBaseSku({ itemGroupId, colorId, sizeId });
  const fetchingInventory = get(inventory, 'fetchingInventory');
  const inventoryCount = get(inventory, key) || 0;
  const isOutOfStock = inventoryCount <= 0 ||
    !includes(
      map(get(variant, 'availableSizesWithId'), size => size.id),
      get(variant, `sizes[${activeSizeIndex}].id`)
    );

  const details = get(storeListings?.productDetails, productId, false);
  const sizeChartData = get(details, 'sizeChart', {});
  const prevColorId = usePrevious(colorId);
  const prevSizeId = usePrevious(sizeId);
  const list = get(location, 'state.list', 'PDP');
  const listingSlug = get(listingProduct, 'url');

  const colorVariables = has(listingProduct, 'primaryProduct') ?
    get(listingProduct, 'primaryProduct') :
    get(listingProduct, 'variations');

  const colorOptions = map(colorVariables, item => ({
    id: get(item, 'variationId'),
    label: get(item, 'color'),
    hex: get(item, 'attributes.hex')
  }));

  const showColors = showSingleOptions &&
    get(colorOptions[activeVariationIndex], 'label.length') > 1;
  const showSizes = showSingleOptions || get(variant, 'availableSizesWithId.length') > 1;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      colorId &&
      sizeId &&
      (prevColorId !== colorId || prevSizeId !== sizeId)
    ) {
      dispatch(fetchListingInventoryCount(colorId, sizeId, listing));
    }
  }, [colorId, sizeId, dispatch, variant, listing]);

  const generateSelectors = (
    type,
    items,
    changeHandler,
    activeIndex,
    activeStyles,
    forceSelectElem
  ) => {
    const formattedItems = type === 'size' ?
      items.map(item => ({
        disabled: !includes(
          get(variant, 'availableSizesWithId').map(size => size.id),
          item.id
        ),
        ...item
      })) :
      items;

    const selectors = !forceSelectElem && bpIsGT('mobileLg') ? (
      <VariantSelector
        type={ type }
        items={ formattedItems }
        onChange={ e => changeHandler(e, list) }
        activeIndex={ activeIndex }
        activeStyles={ activeStyles }
      />
    ) : (
      <SelectField
        items={ formattedItems }
        className="varient_selector__mobile"
        onChange={ changeHandler }
        label={ type[0].toUpperCase() + type.substring(1) }
        activeIndex={ activeIndex }
        defaultValue={ formattedItems[activeIndex]?.label }
      />
    );

    return (
      <>
        { type === 'color' && formattedItems[activeIndex]?.label && (
          <div className="color-label">
            { formattedItems[activeIndex]?.label }
          </div>
        ) }
        { (formattedItems.length > 1 || (type === 'color' && bpIsGT('mobileLg'))) ? (
          selectors
        ) : (
          <SingleSelector item={ formattedItems[0] } type={ type } />
        ) }
      </>
    );
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (stickyMenu) setStickyMenu(false);
    const isValid = event.target.checkValidity();

    if (isValid) {
      const payload = {
        colorId: get(colorOptions[activeVariationIndex], 'id'),
        sizeId: get(variant, `sizes[${activeSizeIndex}].id`),
        productId: get(primaryProduct, 'productId'),
        quantity: quantity || 0,
        itemGroupId
      };

      if (cartData[initialSku]) {
        payload.quantity += parseInt(cartData[initialSku].quantity || 0);
      }
      pushEvent(ADD_TO_CART, {
        currency: variant.currency,
        sku: initialSku,
        title: eventProduct.name,
        productType: primaryProduct.productType,
        price,
        quantity,
        sellerId: eventProduct.dimension8,
        storeSlug: stores.slug,
        storeId: stores.id
      });

      onFormSubmit(payload);

      const userCartItem = {
        [initialSku]: {
          ...payload,
          quantity,
          sku: initialSku,
          slug: location.pathname.split('/')[2]
        }
      };

      ReactGA.event(
        {
          category: 'ecommerce',
          action: 'add_to_cart',
          label: JSON.stringify({ sku: initialSku })
        },
        ['default', 'client']
      );

      ReactPixel.track('AddToCart', {
        content_type: 'product',
        content_name: listingProduct?.title,
        content_url: get(listingProduct, 'url'),
        content_ids: [
          `${get(listingProduct, 'teespringId')}-${get(
            listingProduct,
            'productId'
          )}`
        ],
        value: price,
        currency: get(listingProduct, 'currency')
      });

      dispatch(setActiveModal('cart-confirmation', { userCartItem }));
    }
  };

  const useSizeSelectElem = variant?.sizes?.length > 10 || variant?.sizes?.some(size => size?.label?.length > 5);

  const variantSizes = get(variant, 'sizes');
  const sizeOptionsEl = variantSizes && (
    <>
      <div className="sizeoptions__label">
        { bpIsGT('mobileLg') && <span className="form__label">Size</span> }

        {(!isEmpty(sizeChartData) || get(details, 'sizeChartUrl', '')) && (
          <SizeChartButton sizeChartData={ sizeChartData } details={ details } />
        )}
      </div>
      <>
        {generateSelectors(
          'size',
          get(variant, 'sizes'),
          index => onProductSizeChange(parseInt(index), list),
          activeSizeIndex,
          {
            backgroundColor:
              getStyles('listing.highlightColor') || '#000000'
          },
          useSizeSelectElem
        )}
      </>
    </>
  );

  const colorOptionsEl = colorOptions && (
    <>
      <div className="variant-label-row">
        {bpIsGT('mobileLg') && (<div className="form__label">Color:</div>)}
        {generateSelectors(
          'color',
          colorOptions,
          onProductVariationChange,
          activeVariationIndex,
          { borderColor: getStyles('listing.highlightColor') || '#000000' }
        )}
      </div>
    </>
  );

  const addToCartSticky = (
    <>
      <AnimatePresence>
        {stickyMenu && (
          <Fragment key="stickymenu__fragment">
            <motion.button
              type="button"
              className="stickymenu__overlay"
              key="stickymenu__overlay"
              custom="stickymenu__overlay"
              onClick={ () => setStickyMenu(false) }
              initial={ { opacity: 0 } }
              animate={ { opacity: 1 } }
              exit={ { opacity: 0 } }
              transition={ { duration: 0.2 } }
            />
            <motion.div
              className="stickymenu"
              key="stickymenu"
              custom="stickymenu"
              initial={ { marginBottom: '-100%' } }
              animate={ { marginBottom: '0%' } }
              exit={ { marginBottom: '-100%' } }
              transition={ { duration: 0.3 } }
            >
              <div className="mb2">{colorOptionsEl}</div>
              <div>{sizeOptionsEl}</div>
              <Button btnType="primary" type="submit">
                Add
              </Button>
            </motion.div>
          </Fragment>
        )}
      </AnimatePresence>
      <Button
        className={ isOutOfStock ? 'is-disabled' : '' }
        btnType="primary"
        type="button"
        fullWidth={ true }
        onClick={ () => {
          if (!isOutOfStock) {
            setStickyMenu(true);
            tracker.track(
              'product_page.sticky_add_to_cart.clicked',
              trackPartnersCookieInParams
            );
          }
        } }
      >
        {isOutOfStock ?
          'Out of stock' :
          get(content, 'listing.addToCartBtnLabel') || 'Add to cart'}
      </Button>
    </>
  );

  const addToCartBtn = (
    <Button
      className={ `btn ${isOutOfStock ? 'is-disabled' : ''}` }
      loading={ fetchingInventory }
      type="submit"
    >
      { isOutOfStock ?
        'Out of stock' :
        get(content, 'listing.addToCartBtnLabel') || 'Add to cart'
      }
    </Button>
  );

  const ctaScrollHandler = (event) => {
    const { currentPosition, previousPosition } = event;
    const showCta = (currentPosition === 'below' && previousPosition === 'inside') ||
      (currentPosition === 'above' && previousPosition === 'inside');

    const hideCta = (currentPosition === 'inside' && previousPosition === 'below') ||
      (currentPosition === 'inside' && previousPosition === 'above');

    if (showCta) setCtaScrolledTo(false);
    if (hideCta) setCtaScrolledTo(true);
  };

  const listingCtaEl = (
    <>
      <Waypoint onEnter={ ctaScrollHandler } onLeave={ ctaScrollHandler } />
      {!showPreorderForm && (
        <QtyField
          id="qtyfield"
          value={ quantity }
          onChange={ value => setQuantity(parseInt(value)) }
        />
      )}
      {showPreorderForm ? (
        <PreorderFormButton colorId={ colorId } sizeId={ sizeId } />
      ) : (
        addToCartBtn
      )}
    </>
  );

  return (
    <form
      className="listing-order-form"
      onSubmit={ handleOnSubmit }
    >
      {showColors && (
        <div className="listing__options colors">{colorOptionsEl}</div>
      )}

      {showSizes && <div className="listing__options sizes">{sizeOptionsEl}</div>}

      <div className="listing__cta mb2" data-cy="listing-submit-wrapper">
        {listingCtaEl}
      </div>

      {listingSlug === 'revenge-half-zip-fleece' && (
        <CountDownTimer endDateTime="2024-05-28T06:59:00Z" />
      )}

      {!HIDE_AFTERPAY && usCheckoutOnly && (
        <Elements stripe={ stripePromise }>
          <AfterPay price={ price } currency={ currency } />
        </Elements>
      )}

      <AnimatePresence initial={ false }>
        {bpIsLT('mobileLg') && !ctaScrolledTo && (
          <motion.div
            className="listing__addcart--mobile"
            initial={ { marginBottom: -60 } }
            animate={ { marginBottom: 0 } }
            exit={ { marginBottom: -60 } }
            transition={ { duration: 0.25 } }
          >
            {addToCartSticky}
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
};

ListingForm.propTypes = {
  showSingleOptions: bool,
  activeVariationIndex: number,
  activeSizeIndex: number,
  productId: number.isRequired,
  price: number.isRequired,
  itemGroupId: string.isRequired,
  onFormSubmit: func.isRequired,
  onProductSizeChange: func.isRequired,
  listing: shape({}).isRequired,
  listingProduct: shape({}).isRequired,
  variant: shape({}).isRequired,
  primaryProduct: shape({}).isRequired,
  showPreorderForm: number.isRequired,
  otherProductClicked: bool.isRequired,
  cartData: shape({}).isRequired,
  onProductVariationChange: func.isRequired,
  eventProduct: shape({}).isRequired,
  trackPartnersCookieInParams: string
};

ListingForm.defaultProps = {
  showSingleOptions: true,
  activeVariationIndex: 0,
  activeSizeIndex: 0,
  trackPartnersCookieInParams: ''
};

export default ListingForm;
